// Import Bootstrap Compass integration
//@import "bootstrap-compass";

@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');



body{
	background: url(/img/bg.jpg) no-repeat center bottom -700px;
	margin:0;
	padding:0;
	background-size: cover;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
}

.container{
	//background: white;
    //border: 1px solid #f3f3f3;
    border-radius: 9px;
        padding-bottom: 308px;
}
.logo{
	text-align: center;

	img{
		margin: 0 auto;
	}
}

h2{
	font-size: 35px;
}

#contenu{

	@media (min-width:768px){
		margin-top: 100px;
	}
	h2{
		color: #353535;
		font-size: 35px;
	}



	p{
		font-size: 1em;
	}

}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
ul{
	padding-left: 10px;
	li{
		list-style: none;
		margin-bottom: 5px;
		&:before{
			content: "\f0da";
			font: normal normal normal 18px/1 FontAwesome;
			color: #00ff12;
			margin-right: 7px;
		}
	}
}

.gauche{
	background-color: #b73c3c;
	padding: 20px;
	p, a, h2,li{
		color: white;
	}

	a.group1{
	    display: block;
	    float: left;
	    margin-right: 5px;
	    margin-bottom: 5px;
	}
	.group1 img{
		width: 100px;
	}
	.group1 div{
		width: 100px;
		height: 100px;
	}



}

.group2{
	img{
		width: 100px;
	}
	div{

	}
}
.droite{
	background-color: white;
	text-align: center;

}

.box{
	img{max-width: 100%;}
	text-align: center;
	padding-bottom: 7px;
	h2{
		    padding-top: 12px;
	}
	a.tel{
		font-size: 2em;
		//color: white;
	}
}


#pied{
	margin-top: 20px;
	margin-bottom: 20px;
}


@media (min-width: 0px) and (max-width: 767px) {
  .fsi-row-xs-level {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .fsi-row-sm-level {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .fsi-row-md-level {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 1200px) {
  .fsi-row-lg-level {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
